import { render, staticRenderFns } from "./ExerciseForm.vue?vue&type=template&id=626f8d93&scoped=true&lang=pug&"
import script from "./ExerciseForm.vue?vue&type=script&lang=ts&"
export * from "./ExerciseForm.vue?vue&type=script&lang=ts&"
import style0 from "./ExerciseForm.vue?vue&type=style&index=0&id=626f8d93&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "626f8d93",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDivider } from 'vuetify/lib/components/VDivider';
installComponents(component, {VBtn,VCardText,VCheckbox,VDivider})
